import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { IDiagInfo } from "../types";
import { STEP1_START, HREF_CONTINUE } from "../constants";
import { getLocation, generateUniqueId } from "../utils";

interface IStepResultsProps extends RouteComponentProps {
  diagInfo?: IDiagInfo;
  getNextStep: (currentStep: string, diagRes?: any) => any;
}

function dateExpires(dateExpirationValue: string): string {
  const dateToFmt: Date = new Date(parseInt(dateExpirationValue));
  const day =
    dateToFmt.getDate() < 10
      ? "0" + dateToFmt.getDate()
      : "" + dateToFmt.getDate();
  const month =
    dateToFmt.getMonth() < 9
      ? "0" + (dateToFmt.getMonth() + 1)
      : "" + (dateToFmt.getMonth() + 1);
  return day + "." + month + "." + dateToFmt.getFullYear();
}

class StepResults extends React.PureComponent<IStepResultsProps, {}> {
  constructor(props: IStepResultsProps) {
    super(props);
  }

  checkOrWarning(val: boolean) {
    return val ? "check" : "warning";
  }

  componentDidMount() {
    if (!this.props.diagInfo) {
      this.props.history.push(STEP1_START);
    }
  }


  render() {
    const diagInfo: IDiagInfo | undefined = this.props.diagInfo;
    if (!diagInfo) {
      return null;
    }

    let carmLicStatus = false;
    let carmLicType = "";
    let cspLicStatus = false;
    let carmLicExpiration: string | undefined = "";
    if (diagInfo.params.LICENSES) {
      if (
        diagInfo.params.LICENSES.cryptoarm.status &&
        diagInfo.params.LICENSES.cryptoarm.type !== "permament"
      ) {
        carmLicStatus = true;
      }

      switch (diagInfo.params.LICENSES.cryptoarm.type) {
        case "permament":
          carmLicStatus = true;
          carmLicType = "лицензия действительна";
          break;

        case "temporary":
          carmLicType = "лицензия действует до ";
          carmLicExpiration = diagInfo.params.LICENSES.cryptoarm.expiration;
          if (!carmLicStatus) {
            carmLicType = "лицензия истекла ";
          }
          break;

        default:
          break;
      }

      if (diagInfo.params.LICENSES.csp.status) {
        cspLicStatus = true;
      }
    }

    let carmVersion = "(unknown)";
    if (diagInfo.params.VERSIONS && diagInfo.params.VERSIONS.cryptoarm) {
      carmVersion = diagInfo.params.VERSIONS.cryptoarm;
    }

    const cspAvailable =
      diagInfo.params.CSP_ENABLED || !cspLicStatus ? true : false;

    let cspVersion = "(unknown)";
    if (diagInfo.params.VERSIONS && diagInfo.params.VERSIONS.csp) {
      cspVersion = diagInfo.params.VERSIONS.csp;
    }

    let personalCerts = false;
    if (diagInfo.params.PERSONALCERTIFICATES) {
      const filteredCertificates = diagInfo.params.PERSONALCERTIFICATES.filter(
        (cert) => cert.status === true
      );
      if (filteredCertificates && filteredCertificates.length > 0) {
        personalCerts = true;
      }
    }

    return (
      <React.Fragment>
        <h3 className="last_step_h3">Результаты диагностики</h3> <br />
        <p className="last_step_text" style={{ textAlign: "left" }}>
          Ваше рабочее место может быть использовано для
          {cspLicStatus && carmLicStatus && personalCerts ? (
            <span>
              <b> формирования и проверки электронной подписи</b>
            </span>
          ) : (
            <b> проверки электронной подписи</b>
          )}
          {!cspLicStatus || !carmLicStatus ? (
            <div className="hint">
              <i className="hint_icon " />
              <div className="hint_text no_select">
                Для подписи документов необходима лицензия
                {!cspLicStatus && !carmLicStatus
                  ? " КриптоАРМ ГОСТ и КриптоПро CSP"
                  : cspLicStatus
                  ? " КриптоАРМ ГОСТ"
                  : " КриптоПро CSP"}
              </div>
            </div>
          ) : null}
        </p>
        <br />
        <h3 className="last_step_h3">Программы и сертификаты</h3> <br />
        <div
          className={
            "row_diagnostics last_step_text " +
            this.checkOrWarning(carmLicStatus)
          }
          style={{}}
        >
          КриптоАРМ ГОСТ версия {carmVersion}, {carmLicType}
          {carmLicExpiration ? dateExpires(carmLicExpiration) : null}{" "}
          {carmLicStatus ? (
            ""
          ) : (
            <>
              <a
                className="no_decoration"
                style={{ marginLeft: "20px" }}
                href="https://cryptoarm.ru/shop/cryptoarm-gost"
                target="_blank"
                rel="noopener noreferrer"
              >
                Купить
              </a>
            </>
          )}
        </div>
        <div
          className={
            "row_diagnostics last_step_text " +
            this.checkOrWarning(cspAvailable && cspLicStatus)
          }
        >
          КриптоПро CSP
          {cspAvailable ? (
            <React.Fragment>
              версия {cspVersion}, лицензия{" "}
              {cspLicStatus ? "действительна" : "истекла"}
              {cspLicStatus ? null : (
                <a
                  style={{ marginLeft: "20px" }}
                  href="https://cryptoarm.ru/shop/skzi-cryptopro-csp-5-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Купить
                </a>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              отсутствует{" "}
              <a
                style={{ marginLeft: "20px" }}
                href="https://cryptoarm.ru/shop/skzi-cryptopro-csp-5-0"
                target="_blank"
                rel="noopener noreferrer"
              >
                Купить
              </a>
            </React.Fragment>
          )}
        </div>
        <div
          className={
            "row_diagnostics last_step_text " +
            this.checkOrWarning(personalCerts)
          }
        >
          Личные сертификаты{" "}
          {personalCerts ? (
            <span>
              присутствуют&nbsp; 
              <a       href={
                "cryptoarm://startview/" +
                getLocation() +
                "/api?id=" + generateUniqueId()
              }>показать список
              </a>
            </span>
          ) : (
            "отсутствуют"
          )}
        </div>{" "}
        <br />
        <div
          className="last_step_help"
          style={{marginBottom: 0}}
        >
          {personalCerts ? (
            <React.Fragment>
              <div className="text_next m_t_65">
                Вы можете заказать дополнительный сертификат
              </div>
              <div className="button_help">
                <a
                  href="https://cryptoarm.ru/certificates/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="button">
                    <span className="button_label">Заказать сертификат</span>
                  </div>
                </a>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="text_next">
                Вы можете заказать личный сертификат прямо здесь
              </div>
              <div className="button_help">
                <a
                  href="https://cryptoarm.ru/certificates/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="button">
                    <span className="button_label">Заказать сертификат</span>
                  </div>
                </a>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default StepResults;
