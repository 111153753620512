import {
  PLATFORMS,
  INSTRUCTIONS_LINK,
  INSTRUCTIONS_LINK_CSP,
  CSP_URL,
} from "./constants";

export function getLocation(): string {
  return window.location.protocol + "//" + window.location.host;
}

export function generateUniqueId(): string {
  return uuidv4();
}

// source: https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
function uuidv4(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getLinkToDownload(): string {
  for (const key in PLATFORMS) {
    if (key === detectOS()) {
      return PLATFORMS[key];
    }
  }
  return PLATFORMS.other;
}
export function getLinkToDownloadCSP(): string {
  for (const key in CSP_URL) {
    if (key === detectOS()) {
      return CSP_URL[key];
    }
  }
  return CSP_URL.other;
}
export function getLinkToInstructions(): string {
  for (const key in INSTRUCTIONS_LINK) {
    if (key === detectOS()) {
      return INSTRUCTIONS_LINK[key];
    }
  }
  return INSTRUCTIONS_LINK.other;
}
export function getLinkToInstructionsCSP(): string {
  for (const key in INSTRUCTIONS_LINK_CSP) {
    if (key === detectOS()) {
      return INSTRUCTIONS_LINK_CSP[key];
    }
  }
  return INSTRUCTIONS_LINK_CSP.other;
}
export function detectOS(): string {
  const platform = navigator.platform.toLowerCase();

  if (platform.includes("mac")) return "MacOS";
  if (platform.includes("win")) {
    if (
      navigator.userAgent.indexOf("WOW64") !== -1 ||
      navigator.userAgent.indexOf("Win64") !== -1
    ) {
      return "Windows";
    } else {
      return "Windows 32bit";
    }
  }
  if (platform.includes("linux")) return "Linux";

  return "other";
}
