import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_text">Все права защищены. © 1999-2023 ООО «Цифровые технологии».</div>
      <a className="footer_text no_decoration" href="mailto:support@digt.ru">support@digt.ru</a>
    </div>
  );
};

export default Footer;
