export const SERVICE_URL = "https://id.trusted.plus";
export const SERVICE_TRUSTEDAPP = SERVICE_URL + "/trustedapp";
export const SERVICE_APP = SERVICE_TRUSTEDAPP + "/app";
export const SERVICE_REST = SERVICE_TRUSTEDAPP + "/rest";
export const SERVICE_STORAGE = SERVICE_REST + "/storage";
export const SERVICE_GET_TOKEN = "https://id.trusted.plus/idp/sso/oauth/token";
export const SERVICE_CHECK_TOKEN =
  "https://id.trusted.plus/idp/sso/oauth/check_token";

export const STEP1_START = "/";
export const STEP2_INSTALL_CRYPTOARM = "/StepInstallCryptoArm";
export const STEP3_DOWNLOAD_PROVIDER = "/StepDownloadProvider";
export const STEP4_LICENSE_AGREEMENT = "/StepLicenseAgreement";
export const STEP5_INSTALL_PROVIDER = "/StepInstallProvider";
export const STEP6_CERTIFICATES = "/StepCertificates";
export const STEP7_RESULTS = "/StepResults";

export const CRYPTOARM_VERSION = "v2.5.12";
export const PLATFORMS: { [key: string]: string } = {
  Windows: `https://github.com/TrustedRu/CryptoARMGOST/releases/download/${CRYPTOARM_VERSION}/cryptoarm-gost-${CRYPTOARM_VERSION}-win32-x64.msi`,
  MacOS: `https://github.com/TrustedRu/CryptoARMGOST/releases/download/${CRYPTOARM_VERSION}/cryptoarm-gost-${CRYPTOARM_VERSION}-darwin-x64.dmg`,
  Linux: `https://github.com/TrustedRu/CryptoARMGOST/releases/download/${CRYPTOARM_VERSION}/cryptoarm-gost-${CRYPTOARM_VERSION}-linux-x64.deb`,
  "Windows 32bit": `https://github.com/TrustedRu/CryptoARMGOST/releases/download/${CRYPTOARM_VERSION}/cryptoarm-gost-${CRYPTOARM_VERSION}-win32.msi`,
  other: "https://github.com/TrustedRu/CryptoARMGOST/releases/latest",
};
export const CSP_URL: { [key: string]: string } = {
  Windows: `https://cryptoarm.ru/upload/csp/50/11823/CSPSetup-5.0.11823.zip`,
  MacOS: `https://cryptoarm.ru/upload/csp/50/11823/macos-uni.tgz`,
  Linux: `https://cryptoarm.ru/upload/csp/50/11823/linux-amd64_deb.tgz`,
  "Windows 32bit": `https://cryptoarm.ru/upload/csp/50/11823/CSPSetup-5.0.11823.zip`,
  other: "https://cryptoarm.ru/upload/csp/50/11823/linux-amd64_deb.tgz",
};
export const INSTRUCTIONS_LINK: { [key: string]: string } = {
  Windows: `https://cryptoarm.ru/documentation/kak-ustanovit-kriptoarm-gost-na-platformu-Microsoft-Windows`,
  MacOS: `https://cryptoarm.ru/documentation/kak-ustanovit-kriptoarm-gost-na-platformu-OS-X`,
  Linux: `https://cryptoarm.ru/documentation/kak-ustanovit-kriptoarm-gost-na-platformu-Linux`,
  "Windows 32bit": `https://cryptoarm.ru/documentation/kak-ustanovit-kriptoarm-gost-na-platformu-Microsoft-Windows`,
  other: `https://cryptoarm.ru/documentation/kak-ustanovit-kriptoarm-gost-na-platformu-Linux`,
};
export const INSTRUCTIONS_LINK_CSP: { [key: string]: string } = {
  Windows: `https://cryptoarm.ru/documentation/ustanovka-kriptoprovaydera-na-platformu-ms-windows`,
  MacOS: `https://cryptoarm.ru/documentation/ustanovka-kriptoprovaydera-na-platformu-os-x`,
  Linux: `https://cryptoarm.ru/documentation/ustanovka-kriptoprovaydera-na-platformu-linux`,
  "Windows 32bit": `https://cryptoarm.ru/documentation/ustanovka-kriptoprovaydera-na-platformu-ms-windows`,
  other: `https://cryptoarm.ru/documentation/ustanovka-kriptoprovaydera-na-platformu-linux`,
};
export const CLIENT_ID = "e2754b9465cb028dd652a94fa4662471";
export const REDIRECT_URI_BASE = "https://diagnostic.cryptoarm.ru/code";

export const HREF_CONTINUE =
  "https://update.iecp.ru/referral/db8daaf81be1af00793ab9c1ac38826936b55d9c0ee19e9bb7a48536f0ca2144";
