import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { STEP2_INSTALL_CRYPTOARM, STEP1_START, SERVICE_APP } from "../constants";
import { IDiagInfo } from "../types";
import { getProfileImage } from "../service/userService";
import { GET } from "../utils/fetchUtils";

interface StepStartProps extends RouteComponentProps {
  getNextStep: (currentStep: string, diagRes?: any) => any;
  requestDiagnostics: (
    handleSucess: (diagInfo: IDiagInfo) => any,
    handleFail: () => any
  ) => Promise<any>;
}

export function setProgressCursor(): void {
  document.body.classList.add("in_progress");
}

export function setDefaultCursor(): void {
  document.body.classList.remove("in_progress");
}

class StepStart extends React.PureComponent<StepStartProps, {}> {
  constructor(props: any) {
    super(props);
  }

  componentDidUpdate(
    prevProps: Readonly<StepStartProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    setDefaultCursor();
  }

  componentDidMount() {
    getProfileImage();
  }

  handleBegin = (): void => {
    setProgressCursor();

    const { history, requestDiagnostics, getNextStep } = this.props;
    if (requestDiagnostics) {
      requestDiagnostics(
        (diagInfo: IDiagInfo) =>
          history.push(getNextStep(STEP1_START, diagInfo)),
        () => history.push(STEP2_INSTALL_CRYPTOARM)
      );
    } else {
      history.push(STEP2_INSTALL_CRYPTOARM);
    }
  };

  render() {
    return (
      <React.Fragment>
        <h3>
          Для проведения диагностики необходимо проверить <br /> установленные
          компоненты
        </h3>
        <div className="m_t_65">
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="help no_decoration"
            href="https://cryptoarm.ru/documentation/diagnostic_instruction"
          >
            Инструкция по использованию
          </a>
        </div>
        <div className="button_help">
          <div className="button" onClick={this.handleBegin}>
            <span className="button_label">Приступить</span>
          </div>
        </div>
        <div className="text first_step_text">
          Нажимая на кнопку «Приступить», вы выражаете свое согласие на
          обработку конфигурационной информации и информации о компонентах,
          необходимых для корректной работы программ(ы) для ЭВМ, с целью их
          диагностирования и последующего решения технических проблем.
        </div>
      </React.Fragment>
    );
  }
}

export default StepStart;
