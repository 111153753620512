import Cookies, { CookieSetOptions } from "universal-cookie";
import {
  SERVICE_URL,
  SERVICE_GET_TOKEN,
  SERVICE_CHECK_TOKEN,
  REDIRECT_URI_BASE,
  STEP3_DOWNLOAD_PROVIDER,
} from "../constants";
const cookies = new Cookies();
const ACCESS_TOKEN_EXPIRATION_SEC = 900;
const REFRESH_TOKEN_EXPIRATION_SEC = 3600;

const str2ab = (str: string): ArrayBuffer => {
  const encoder = new TextEncoder();
  return encoder.encode(str);
};

const sha256 = async (str: string): Promise<ArrayBuffer> => {
  const data = str2ab(str);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  return hashBuffer;
};

const btoaRFC7636 = (buf: ArrayBuffer): string => {
  let binary = "";
  const bytes = new Uint8Array(buf);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary).replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
};

//ie 11.x uses msCrypto
declare global {
  interface Window {
    msCrypto: Crypto;
  }
}

export const getCrypto = (): Crypto => {
  return window.crypto || window.msCrypto;
};

const randomString = (): string => {
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
  let str = "";
  const randomValues = Array.from(
    getCrypto().getRandomValues(new Uint8Array(43))
  );
  randomValues.forEach((v) => (str += charset[v % charset.length]));
  return str;
};

const setCodeVerifier = (): string => {
  const codeVerifier = randomString();
  window.localStorage.setItem("codeVerifier", codeVerifier);
  return codeVerifier;
};

const HOST = process.env.REACT_APP_HOST as string;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string;

export const showWidget = async (): Promise<void> => {
  const codeVerifier = setCodeVerifier();
  const codeVerifierBase64 = btoaRFC7636(str2ab(codeVerifier));
  const codeChallenge = btoaRFC7636(await sha256(codeVerifierBase64));
  const url =
    SERVICE_URL +
    "/idp/sso/oauth" +
    "?client_id=" +
    CLIENT_ID +
    "&redirect_uri=" +
    encodeURIComponent(REDIRECT_URI_BASE) +
    "&scope=" +
    "userprofile" +
    "&code_challenge=" +
    codeChallenge +
    "&code_challenge_method=" +
    "S256";
  window.location.href = url;
};

const getCode = (): string => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  return url.searchParams.get("code") as string;
};

export const removeTokenCookies = (): void => {
  cookies.remove("accessToken");
  cookies.remove("refreshToken");
};

export const getTokenCookies = (): {
  accessToken: string | undefined;
  refreshToken: string | undefined;
} => {
  return {
    accessToken: cookies.get("accessToken"),
    refreshToken: cookies.get("refreshToken"),
  };
};

export const setTokenCookies = (
  accessToken: string,
  refreshToken: string,
  expiresIn: number
): void => {
  accessToken && refreshToken && removeTokenCookies();

  const cookieAttributes: CookieSetOptions = {
    secure: true,
    sameSite: "strict",
    path: "/",
  };

  const accessTokenExpiration = new Date();
  accessTokenExpiration.setSeconds(
    accessTokenExpiration.getSeconds() + expiresIn ||
      ACCESS_TOKEN_EXPIRATION_SEC
  );
  accessToken &&
    cookies.set("accessToken", accessToken, {
      ...cookieAttributes,
      expires: accessTokenExpiration,
    });

  const refreshTokenExpiration = new Date();
  refreshTokenExpiration.setSeconds(
    refreshTokenExpiration.getSeconds() + REFRESH_TOKEN_EXPIRATION_SEC
  );
  refreshToken &&
    cookies.set("refreshToken", refreshToken, {
      ...cookieAttributes,
      expires: refreshTokenExpiration,
    });
};

type tokenResponse = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
};

export const getAccessTokenByCode = async (): Promise<void> => {
  const codeVerifier = window.localStorage.getItem("codeVerifier") as string;
  const codeVerifierBase64 = btoaRFC7636(str2ab(codeVerifier));

  const code = getCode() as string;
  if (!code) {
    console.error("No code");
    window.location.href = "/";
    return;
  }

  const body = [
    "grant_type=authorization_code",
    "redirect_uri=" + encodeURIComponent(HOST + "/code"),
    "client_id=" + CLIENT_ID,
    "code=" + code,
    "code_verifier=" + codeVerifierBase64,
    "scope=userprofile",
  ].join("&");
  const headers = new Headers();
  headers.set("Content-Type", "application/x-www-form-urlencoded");
  try {
    const response = await fetch(SERVICE_GET_TOKEN, {
      method: "POST",
      headers,
      body,
    });
    const json = (await response.json()) as tokenResponse;
    const {
      access_token: accessToken,
      refresh_token: refreshToken,
      expires_in: expiresIn,
    } = json;
    setTokenCookies(accessToken, refreshToken, expiresIn);
    // eslint-disable-next-line require-atomic-updates
    window.location.href = STEP3_DOWNLOAD_PROVIDER;
  } catch (error) {
    console.error(error);
    return;
  }
};

const getAccessTokenByRefreshToken = async (
  refreshToken: string
): Promise<string | undefined> => {
  const formData = new FormData();
  formData.append("grant_type", "refresh_token");
  formData.append("refresh_token", refreshToken);
  const headers = new Headers();
  headers.set("Authorization", "Basic " + btoa(CLIENT_ID + ":"));

  try {
    const response = await fetch(SERVICE_GET_TOKEN, {
      method: "POST",
      headers,
      body: formData,
    });
    const json = (await response.json()) as tokenResponse;
    const {
      access_token: accessToken,
      refresh_token: refreshToken,
      expires_in: expiresIn,
    } = json;
    setTokenCookies(accessToken, refreshToken, expiresIn);
    return accessToken;
  } catch (error) {
    console.error(error);
  }
};

export const getAccessToken = async (): Promise<string | undefined> => {
  const { accessToken, refreshToken } = getTokenCookies();
  if (accessToken !== undefined) {
    return accessToken;
  }
  if (refreshToken !== undefined) {
    return getAccessTokenByRefreshToken(refreshToken);
  }
};

type validityResponse =
  | {
      active: boolean;
      exp: number;
      user_name: string;
      authorities: string[];
      clientId: string;
      scope: string[];
    }
  | {
      error: string;
      error_description: string;
    };

export const checkAccessTokenValidity = async (
  accessToken: string
): Promise<boolean> => {
  const formData = new FormData();
  formData.append("token", accessToken);
  const headers = new Headers();
  headers.set("Authorization", "Basic " + btoa(CLIENT_ID + ":"));
  try {
    const response = await fetch(SERVICE_CHECK_TOKEN, {
      method: "POST",
      headers,
      body: formData,
    });
    const json = (await response.json()) as validityResponse;
    if ("error" in json) {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
  return true;
};
