import { getAccessToken } from "./authService";
import { User, UserData } from "../domain/User";
import { GET } from "../utils/fetchUtils";
import {SERVICE_APP, SERVICE_REST, SERVICE_STORAGE} from "../constants";

export const getProfileImage = async (): Promise<UserData> => {
  const user: User = await GET(SERVICE_REST + "/user/profile/get");
  // const user: User = await GET(SERVICE_REST + '/user/profile/get');
  const result: UserData = user.data;

  const thumbnailUrl = user.data.properties.find(
    (prop) => prop.type === "thumbnailUrl"
  );
  if (thumbnailUrl) {
    const fetchAvatarToBlob = async (thumbnailUrl: string): Promise<string> => {
      const accessToken = await getAccessToken()
      const response = await fetch(thumbnailUrl, {
        method: "GET",
        headers: { Authorization: "Bearer " + accessToken },
      });
      const blobUrl = URL.createObjectURL(await response.blob());

      return blobUrl;
    };
    result.thumbnailBlobUrl = await fetchAvatarToBlob(
      SERVICE_STORAGE + "/" + thumbnailUrl.value
    );
  }

  const userHome = user.data.properties.find(
    (prop) => prop.type === "user_home"
  );
  if (userHome) {
    result.userHome = userHome.value;
  }

  delete user.data.properties;
  return result;
};
