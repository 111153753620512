/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { STEP6_CERTIFICATES, STEP7_RESULTS } from "../constants";
import { setProgressCursor } from "./StepStart";
import { IDiagInfo } from "../types";

interface StepCertificatesProps extends RouteComponentProps {
  diagInfo?: IDiagInfo;
  getNextStep: (currentStep: string, diagRes?: any) => any;
  requestDiagnostics: (
    handleSucess: (diagInfo: IDiagInfo) => any,
    handleFail: () => any
  ) => Promise<any>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
class StepCertificates extends React.PureComponent<StepCertificatesProps, {}> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: StepCertificatesProps) {
    super(props);
  }

  handleNext = () => {
    const { history, requestDiagnostics, getNextStep } = this.props;

    if (requestDiagnostics) {
      requestDiagnostics(
        (diagInfo: IDiagInfo) => {
          history.push(getNextStep(STEP7_RESULTS, diagInfo));
        },
        () => history.push(STEP6_CERTIFICATES)
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    return (
      <React.Fragment>
        <h3>Личные сертификаты не найдены</h3>
        <h4>
          У вас уже есть личный сертификат?
          <br />
          Для его подключения к рабочему месту вы можете:
        </h4>
        <div className="row first_row">
          <a href="#">Установить сертификат электронной подписи с токена</a>
        </div>
        <div className="row second_row">
          <a href="#">Установить сертификат из сервиса DSS</a>
        </div>
        <div className="row third_row">
          <a href="#">Установить сертификат из файла </a>
        </div>

        <h4>Нажмите «Далее» если у вас нет действующего сертификата</h4>

        <div
          className="button_help"
          onClick={() => {
            setProgressCursor();
            this.handleNext();
          }}
        >
          <div className="button">Далее</div>
        </div>
      </React.Fragment>
    );
  }
}

export default StepCertificates;
