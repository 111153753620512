import React from "react";
import { STEP5_INSTALL_PROVIDER } from "../constants";

interface IStepLicenseAgreementState {
  agreementAccepted: boolean;
}

class StepLicenseAgreement extends React.PureComponent<
  any,
  IStepLicenseAgreementState
> {
  constructor(props: any) {
    super(props);

    this.state = { agreementAccepted: false };
  }

  handleCheck = () => {
    const checkBox: any = document.getElementById("agreementCheck");
    if (checkBox) {
      const agreementAccepted = !this.state.agreementAccepted;
      this.setState({ agreementAccepted });
    }
  };

  handleFollowNext = () => {
    if (this.state.agreementAccepted) {
      this.props.history.push(STEP5_INSTALL_PROVIDER);
    }
  };

  render() {
    return (
      <React.Fragment>
        <h3>Лицензионное соглашение</h3>
        <div className="lic_agree_text">
          <span style={{fontSize: 16, color: "black"}}><b>КриптоПро CSP</b></span>
          <p>
            <br />
            Срок использования демонстрационной версии КриптоПро CSP ограничен 90
            днями с момента установки.
            <br />
            Передача прав на использование программного
            обеспечения производства ООО "КРИПТО-ПРО" осуществляется на основании
            Лицензионного соглашения.
            <br />
            Лицензии на использование продукта
            необходимо приобретать в ООО "КРИПТО-ПРО" или у официального дилера.
            <br />
            Лицензии на право использования программного обеспечения оформляются
            на бумажном носителе формата А4.
            <br /><br />
            <span style={{color: "black"}}><b>Предупреждение.</b></span>
            <br />
            Для штатной эксплуатации средств криптографической защиты информации (СКЗИ) они
            должны быть установлены с дистрибутива.
            <br /><br />
            <span style={{color: "black"}}><b>Дистрибутив может быть:</b></span>
            -Приобретен у производителя или у официального дилера производителя на
            материальном носителе. Срок использования демонстрационной версии
            КриптоПро CSP ограничен 90 днями с момента установки. Передача прав на
            использование программного обеспечения производства ООО "КРИПТО-ПРО"
            осуществляется на основании Лицензионного соглашения. Лицензии на
            использование продукта необходимо приобретать в ООО "КРИПТО-ПРО" или у
            официального дилера. Лицензии на право использования программного
            обеспечения оформляются на бумажном носителе формата А4.
            Предупреждение. Для штатной эксплуатации средств криптографической
            защиты информации (СКЗИ) они должны быть установлены с дистрибутива.
            <br /><br />
            <span style={{color: "black"}}><b>Дистрибутив может быть:</b></span>
            <br />
            - Приобретен у производителя или у
            официального дилера производителя на материальном носителе.
          </p>
        </div>
        <div className="lic_agree_in_help">
          <input
            type="checkbox"
            id="agreementCheck"
            className="lic_agree_in"
            onClick={this.handleCheck}
          />
          Я согласен с Лицензионным соглашением
        </div>
        <div className="button_help">
          { this.state.agreementAccepted
            ? <div className="button" onClick={this.handleFollowNext}>
                <span className="button_label">Перейти к загрузке</span>
              </div>
            : <div className="button not_agreed_button">
                <span className="button_label">Перейти к загрузке</span>
              </div> }
        </div>
      </React.Fragment>
    );
  }
}

export default StepLicenseAgreement;
