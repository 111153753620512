/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { setDefaultCursor, setProgressCursor } from "./StepStart";
import { STEP2_INSTALL_CRYPTOARM } from "../constants";
import { IDiagInfo } from "../types";
import { detectOS, getLinkToDownload, getLinkToInstructions } from "../utils";

interface StepStartProps extends RouteComponentProps {
  getNextStep: (currentStep: string, diagRes?: any) => any;
  requestDiagnostics: (
    handleSucess: (diagInfo: IDiagInfo) => any,
    handleFail: () => any
  ) => Promise<any>;
}

class StepInstallCryptoArm extends React.PureComponent<StepStartProps, {}> {
  constructor(props: any) {
    super(props);

    setDefaultCursor();
  }

  componentDidUpdate(
    prevProps: Readonly<StepStartProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    setDefaultCursor();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleNext = () => {
    const { history, requestDiagnostics, getNextStep } = this.props;

    if (requestDiagnostics) {
      requestDiagnostics(
        (diagInfo: IDiagInfo) =>
          history.push(getNextStep(STEP2_INSTALL_CRYPTOARM, diagInfo)),
        () => history.push(STEP2_INSTALL_CRYPTOARM)
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    return (
      <React.Fragment>
        <h3>Отсутствует компонент для проведения диагностики</h3>
        <h4 style={{ height: "38px" }}>Установите приложение КриптоАРМ ГОСТ</h4>
        <div className="install_CA install_CA_3_step">
          <div className="CA_col first">
            <p className="step_label">
              Скачайте приложение <br /> и запустите загруженный файл
            </p>
            <a href={getLinkToDownload()}>Скачать для {detectOS()}</a>
            <div className="CA_col_img tr_setup_img"></div>
          </div>
          <div className="arrow"></div>
          <div className="CA_col second">
            <p className="step_label">
              После установки
              <br />
              приложения нажмите «Далее»
            </p>
          </div>
        </div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          className="help help_install no_decoration"
          href={getLinkToInstructions()}
        >
          Инструкция по установке
        </a>
        <div className="button_help">
          <div
            className="button"
            onClick={() => {
              setProgressCursor();
              this.handleNext();
            }}
          >
            <span className="button_label">Далее</span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StepInstallCryptoArm;
