import React from "react";
import { useLocation } from "react-router-dom";
import {
  STEP1_START,
  STEP2_INSTALL_CRYPTOARM,
  STEP3_DOWNLOAD_PROVIDER,
  STEP4_LICENSE_AGREEMENT,
  STEP5_INSTALL_PROVIDER,
  STEP6_CERTIFICATES,
  STEP7_RESULTS,
} from "./constants";

const Header = () => {
  const curLocation = useLocation();
  const getProgressClassForLocation = () => {
    switch (curLocation.pathname) {
      case STEP1_START:
        break;

      case STEP2_INSTALL_CRYPTOARM:
        return "red_line red_line_20";

      case STEP3_DOWNLOAD_PROVIDER:
        return "red_line red_line_35";

      case STEP4_LICENSE_AGREEMENT:
        return "red_line red_line_35";

      case STEP5_INSTALL_PROVIDER:
        return "red_line red_line_75";

      case STEP6_CERTIFICATES:
        return "red_line red_line_75";

      case STEP7_RESULTS:
        return "red_line red_line_100";

      default:
        break;
    }

    return "red_line";
  };

  return (
    <React.Fragment>
      {curLocation.pathname !== STEP7_RESULTS ? (
        <>
          {/* <div className="login_header">
            {[STEP1_START, STEP2_INSTALL_CRYPTOARM, STEP3_DOWNLOAD_PROVIDER].includes(curLocation.pathname)
              ? <a className="login_link no_decoration" href="#">Войти</a>
              : <a className="user_picture no_decoration" href="#" />
            }
          </div> */}
          <h2>Диагностика рабочего места</h2>
        </>
      ) : (
        <div className="last_step_header">
          <h2 className="last_step_h2">Диагностика завершена</h2>
          <a
            className="user_picture no_decoration"
            style={{ marginTop: "15px" }}
            href="#"
          />
        </div>
      )}

      {curLocation.pathname == STEP7_RESULTS ? null : (
        <div className="header_text">
          Проверка и настройка компьютера для работы с
          <br />
          электронной подписью
        </div>
      )}

      <div className="progress_bar">
        <div className={getProgressClassForLocation()}></div>
      </div>
    </React.Fragment>
  );
};

export default Header;
