import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { STEP5_INSTALL_PROVIDER } from "../constants";
import { getLinkToInstructionsCSP, detectOS, getLinkToDownloadCSP } from "../utils";

interface StepInstallProviderProps extends RouteComponentProps {
  getNextStep: (currentStep: string, diagRes?: any) => any;
}

class StepInstallProvider extends React.PureComponent<
  StepInstallProviderProps,
  {}
  > {
  constructor(props: StepInstallProviderProps) {
    super(props);
  }

  handleNext = () => {
    const { history, getNextStep } = this.props;
    history.push(getNextStep(STEP5_INSTALL_PROVIDER));
  };

  render() {
    return (
      <React.Fragment>
        <h3>Установите криптопровайдер КриптоПро CSP</h3>
        <div className="install_CA">
          <div className="CA_col first">
            <p className="step_label">
              Скачайте приложение и запустите загруженный фаил
            </p>
            <a href={getLinkToDownloadCSP()}>Скачать для {detectOS()}</a>
          </div>
          <div className="arrow"></div>
          <div className="CA_col second">
            <p className="step_label">
              После установки
              <br /> нажмите "Далее"
            </p>
            <div className="CA_col_img tr csp_setup_img"></div>
          </div>
        </div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          className="help no_decoration"
          href={getLinkToInstructionsCSP()}
          style={{ display: "block", marginTop: 50 }}
        >
          Инструкция по установке
        </a>
        <div className="button_help" onClick={this.handleNext}>
          <div className="button">
            <span className="button_label">Далее</span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StepInstallProvider;
