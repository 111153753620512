import Footer from "./Footer";
import Header from "./Header";
import StepStart from "./diagnostic/StepStart";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import StepInstallCryptoArm from "./diagnostic/Step2InstallCryptoarm";
import StepDownloadProvider from "./diagnostic/Step3DownloadProvider";
import StepLicenseAgreement from "./diagnostic/Step4LicenseAgreement";
import StepInstallProvider from "./diagnostic/Step5InstallProvider";
import StepCertificates from "./diagnostic/Step6Certificates";
import StepResults from "./diagnostic/Step7Results";
import {
  STEP1_START,
  STEP2_INSTALL_CRYPTOARM,
  STEP3_DOWNLOAD_PROVIDER,
  STEP4_LICENSE_AGREEMENT,
  STEP5_INSTALL_PROVIDER,
  STEP6_CERTIFICATES,
  STEP7_RESULTS,
} from "./constants";
import React, { ReactNode } from "react";
import { IDiagInfo } from "./types";
import { getLocation, generateUniqueId } from "./utils";
import { getAccessTokenByCode } from "./service/authService";

interface IAppState {
  diagInfo?: IDiagInfo;
}

class App extends React.Component<any, IAppState> {
  constructor(props: any) {
    super(props);
    this.state = { diagInfo: undefined };
  }

  fetchResult = async (id: string) => {
    let diagInfo: IDiagInfo | undefined = undefined;

    try {
      const resp = await fetch(
        getLocation() + "/cb?id=" + id + "&cmdRes=diagnostics"
      );
      const data = await resp.json();
      if (data && data.completed) {
        diagInfo = data;

      } else {
        if (data && !data.completed) {
          console.error("Request is not processed.");
        } else {
          console.error("Time is out!");
        }
      }
    } catch (error) {
      console.error(error.message);
    }

    if (diagInfo) {
      this.setState({ diagInfo });
    }

    return diagInfo;
  };

  handleRequestDiagnostics = async (
    handleSucess: (diagInfo: IDiagInfo) => any,
    handleFail: () => any
  ) => {
    let diagInfo: IDiagInfo | undefined = undefined;

    try {
      const id = generateUniqueId();
      await fetch(getLocation() + "/cb?id=" + id + "&cmd=diagnostics");
      const customProtocolCheck = require("custom-protocol-check");
      customProtocolCheck(
        "cryptoarm://diagnostics/" + getLocation() + "/api?id=" + id,
        () => {
          console.log("Custom protocol not found.");
          handleFail();
        },
        async () => {
          console.log(
            "Custom protocol found and opened the file successfully."
          );
          diagInfo = await this.fetchResult(id);
          if (diagInfo) {
            handleSucess(diagInfo);
          } else {
            handleFail();
          }
        },
        3000,
        async () => {
          console.log("Browser is not supported");
          window.location.href =
            "cryptoarm://diagnostics/" + getLocation() + "/api?id=" + id;
          diagInfo = await this.fetchResult(id);
          if (diagInfo) {
            handleSucess(diagInfo);
          } else {
            handleFail();
          }
        }
      );
    } catch (error) {
      console.error("Request Diagnostics error: " + error.message);
    }
  };

  handleGetNextStep = (currentStep: string, diagRes?: IDiagInfo): string => {
    const diag: IDiagInfo | undefined = diagRes ? diagRes : this.state.diagInfo;
    let personalCerts = false;
    if (diag) {
      if (diag.params.PERSONALCERTIFICATES) {
        const filteredCertificates = diag.params.PERSONALCERTIFICATES.filter(
          (cert) => cert.status === true
        );
        if (filteredCertificates && filteredCertificates.length > 0) {
          personalCerts = true;
        }
      }
    }
    if (!diag) {
      return STEP2_INSTALL_CRYPTOARM;
    }
    if (!personalCerts) {
      switch (currentStep) {
        case STEP1_START:
          return STEP6_CERTIFICATES;
      }
    }
    if (!diag.params.CSP_ENABLED) {
      switch (currentStep) {
        case STEP1_START:
        case STEP2_INSTALL_CRYPTOARM:
          return STEP3_DOWNLOAD_PROVIDER;

        case STEP3_DOWNLOAD_PROVIDER:
          return STEP4_LICENSE_AGREEMENT;

        case STEP4_LICENSE_AGREEMENT:
          return STEP5_INSTALL_PROVIDER;

        default:
          break;
      }
    }

    switch (currentStep) {
      case STEP1_START:
      case STEP2_INSTALL_CRYPTOARM:
      case STEP3_DOWNLOAD_PROVIDER:
      case STEP4_LICENSE_AGREEMENT:
      case STEP5_INSTALL_PROVIDER:
      case STEP6_CERTIFICATES:
        return STEP7_RESULTS;

      default:
        break;
    }

    return STEP1_START;
  };

  render() {
    return (
      <Router>
        <div className="container">
          <div className="content">
          <Header />
            <Switch>
              <Route
                exact
                path={STEP1_START}
                render={(props) => (
                  <StepStart
                    requestDiagnostics={this.handleRequestDiagnostics}
                    getNextStep={this.handleGetNextStep}
                    {...props}
                  />
                )}
              />
              <Route
                path={STEP2_INSTALL_CRYPTOARM}
                render={(props) => (
                  <StepInstallCryptoArm
                    requestDiagnostics={this.handleRequestDiagnostics}
                    getNextStep={this.handleGetNextStep}
                    {...props}
                  />
                )}
              />
              <Route
                path={STEP3_DOWNLOAD_PROVIDER}
                render={(props) => <StepDownloadProvider {...props} />}
              />
              <Route
                path={STEP4_LICENSE_AGREEMENT}
                render={(props) => (
                  <StepLicenseAgreement
                    getNextStep={this.handleGetNextStep}
                    {...props}
                  />
                )}
              />
              <Route
                path={STEP5_INSTALL_PROVIDER}
                render={(props) => (
                  <StepInstallProvider
                    getNextStep={this.handleGetNextStep}
                    {...props}
                  />
                )}
              />
              <Route
                path={STEP6_CERTIFICATES}
                render={(props) => (
                  <StepCertificates
                    requestDiagnostics={this.handleRequestDiagnostics}
                    getNextStep={this.handleGetNextStep}
                    diagInfo={this.state.diagInfo}
                    {...props}
                  />
                )}
              />
              <Route
                path={STEP7_RESULTS}
                render={(props) => (
                  <StepResults
                    getNextStep={this.handleGetNextStep}
                    diagInfo={this.state.diagInfo}
                    {...props}
                  />
                )}
              />
              <Route
                path="/code"
                exact
                render={(): ReactNode => {
                  getAccessTokenByCode();
                  return null;
                }}
              />
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
