import { getAccessToken } from "../service/authService";

const request = async (
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  additionalHeaders?: object,
  body?: string
): Promise<any> => {
  const accessToken = await getAccessToken();
  if (accessToken === undefined) {
    // window.location.href = '/'
  }
  const headers = {
    ...additionalHeaders,
    Authorization: "Bearer " + accessToken,
  };
  const response = await fetch(url, { method, headers, body });

  const json = await response.json();

  if (json.success === false) {
    throw Error(json.message)
  }
  return json.toString();
}

export const GET = (
  url: string,
  headers?: object,
  body?: string
): Promise<any> => {
  return request(url, "GET", headers, body);
}