import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { STEP4_LICENSE_AGREEMENT } from "../constants";
import { getAccessToken, showWidget } from "../service/authService";
import { getLinkToInstructionsCSP } from "../utils";

class StepDownloadProvider extends React.PureComponent<
  RouteComponentProps,
  {}
> {
  constructor(props: RouteComponentProps) {
    super(props);
  }

  authCheck = async (): Promise<void> => {
    const { history } = this.props;

    const accessToken = await getAccessToken();
    if (accessToken === undefined) {
      //
    } else {
      history.push(STEP4_LICENSE_AGREEMENT);
    }
  };

  componentDidMount(): void {
    this.authCheck();
  }

  render() {
    return (
      <React.Fragment>
        <h3>Отсутствует криптопровайдер КриптоПро CSP</h3>
        <h4 style={{ height: "34px" }}>
          Для скачивания дистрибутива КриптоПро CSP авторизуйтесь на{" "}
          trusted.plus, <br /> либо пройдите регистрацию
        </h4>
        <div className="install_CA">
          <div className="CA_col first">
            <p className="step_label">
              Войдите под вашей учетной записью или пройдите регистрацию
            </p>
          </div>
          <div className="arrow"></div>
          <div className="CA_col second">
            <p className="step_label">
              После установки
              <br />
              приложения нажмите «Далее»
            </p>
            <div className="CA_col_img csp_setup_img"></div>
          </div>
        </div>
        <div className="button_help" style={{ marginTop: 47 }}>
          <div className="button" onClick={() => showWidget()}>
            <span className="button_label">Вход и регистрация</span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StepDownloadProvider;
